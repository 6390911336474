import React, { useState, useEffect } from 'react';
import './Blogs.css';
import firebase from '../firebase';
import { Link } from 'react-router-dom'; // Import Link component

const Blogs = () => {
  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firebase.firestore().collection('blogs').get();
        const blogs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setBlogsData(blogs);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="blogs">
      <h2>Blogs</h2>
      {blogsData.map((blog, index) => (
        <div key={index} className="blog-item">
          <Link to={`/blogs/${blog.id}`} className="blog-link"> {/* Pass the blog ID as a parameter */}
            <div className="blog-content">
              <div className="blog-image">
                <img src={blog.imageUrl} alt="Blog Thumbnail" />
              </div>
              <div className="blog-info">
                <div className="blog-title">{blog.title}</div>
                <div className="blog-preview">{blog.preview.split(' ').slice(0, 100).join(' ')}...</div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Blogs;
