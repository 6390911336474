import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from '../firebase';
import './BlogDetails.css'; // Import the CSS file for BlogDetails

const BlogDetails = () => {
  const { id } = useParams(); // Get the blog ID from the URL parameter
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const docRef = await firebase.firestore().collection('blogs').doc(id).get();
        if (docRef.exists) {
          setBlog(docRef.data());
        } else {
          console.log('Blog not found');
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-details">
      <div className="blog-image-container">
        <img src={blog.imageUrl} alt="Blog Thumbnail" className="blog-image" />
      </div>
      <div className="blog-content-container">
        <h2 className="blog-title">{blog.title}</h2>
        <h3 className="blog-preview">{blog.preview}</h3>
        <p className="blog-content">{blog.content}</p>
      </div>
    </div>
  );
};

export default BlogDetails;
