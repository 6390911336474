import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact">
      <h2>Contact Us</h2>
      <p>For any inquiries or questions, please feel free to contact us via email or through our social media channels.</p>

      <div className="contact-info">
        <div className="email">
          <p>Email: contact@ada-innovation.com</p>
        </div>
        <div className="social-media">
          <p>Follow us on:</p>
          <ul>
            <li>
              <a href="https://www.facebook.com/ada-innovation" target="_blank" rel="noopener noreferrer">Facebook</a>
            </li>
            <li>
              <a href="https://www.twitter.com/ada-innovation" target="_blank" rel="noopener noreferrer">Twitter</a>
            </li>
            <li>
              <a href="https://www.instagram.com/ada-innovation" target="_blank" rel="noopener noreferrer">Instagram</a>
            </li>
            {/* Add more social media accounts as needed */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
