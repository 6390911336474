import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2>About Us Page</h2>
      <p>Welcome to the ADA Innovation Center! We are a team of passionate individuals...</p>
    </div>
  );
};

export default AboutUs;
