import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Blogs from './pages/Blogs';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import BlogDetails from './pages/BlogDetails';
import Footer from './components/Footer'; // Import the Footer component

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/blogs" exact element={<Blogs/>} />
          <Route path="/about" exact element={<AboutUs/>} />
          <Route path="/contact" exact element={<Contact/>} />
          <Route path="/blogs/:id" element={<BlogDetails/>} />
        </Routes>
        <Footer /> {/* Add the Footer component */}
      </div>
    </Router>
  );
};

export default App;