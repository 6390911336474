import firebase from 'firebase/compat/app'; // Import from 'firebase/compat/app' instead of 'firebase/app'
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCFsrHXs3tM--IZmCE81AWdMqxLtrEysKY",
    authDomain: "my-blog-f917d.firebaseapp.com",
    projectId: "my-blog-f917d",
    storageBucket: "my-blog-f917d.appspot.com",
    messagingSenderId: "84277780141",
    appId: "1:84277780141:web:8dc623a9ac86851d71b024"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;


