import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <h2>Welcome to ADA Innovation Center</h2>
      <p>
        The ADA Innovation Center is a hub for creative minds, innovators, and problem solvers.
        We believe in pushing boundaries and transforming ideas into reality.
      </p>
      <p>
        Our team is dedicated to driving innovation and making a positive impact on society through technology.
        Whether you are an experienced professional or a curious learner, join us on this journey of innovation!
      </p>
    </div>
  );
};

export default Home;
